import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { fetchSingleStory, fetchPhotoByStoryId } from "../../util/outbound";
import Loader from "../shared/Loader";
import { useNavigate } from "react-router-dom";

const SingleStory = ({ respectDenial = true }) => {
    const params = useParams();
    const storyId = params.id;
    const [isLoading, setLoading] = useState(true);
    const [currentStory, setCurrentStory] = useState(null);
    const [assocPhoto, setAssocPhoto] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const op = async () => {
            const fetchedStory = await fetchSingleStory(storyId);
            const associatedPhoto = await fetchPhotoByStoryId(storyId);
            if (respectDenial && fetchedStory && !fetchedStory.approved){
                setCurrentStory(undefined);
            }
            else{
                setCurrentStory(fetchedStory);
            }
            if (respectDenial && associatedPhoto && !associatedPhoto.approved){
                setAssocPhoto(undefined);
            }
            else{
                setAssocPhoto(associatedPhoto);
            }
            setLoading(false);
        }
        op();
    }, []);


    return (
        <div id="tejas-single-story" style={{
            paddingTop: '5vw'
        }}>
            {isLoading ? (
                <Loader style={{paddingTop: '10vw'}}/>
            ) : (
                currentStory === undefined && assocPhoto === undefined ? (
                    <div id="tejas-single-story-header"  style={{
                        fontFamily: "'EB Garamond'",
                        paddingTop: '10vw'
                    }}>
                        <h2 style={{paddingBottom: "4vw", color: 'red'}}>This resource does not exist or has not been approved yet.</h2>
                        <div id="tejas-create-form-attach-story-option" style={{fontFamily: "'Bellota', cursive"}}>
                            <Button style={{margin: '1vw'}} variant="outline-secondary" onClick={() => navigate('/stories')}>Back to Stories</Button>
                            <Button style={{margin: '1vw'}} variant="outline-info" onClick={() => navigate('/stories/create')}>Create your own story</Button>
                        </div>
                    </div>
                ) : 
                (
                    <div id="tejas-single-story-header"  style={{
                        fontFamily: "'EB Garamond'"
                    }}>
                        <h2 style={{fontFamily: "'Bellota', cursive", paddingBottom: "4vw"}}><i>{currentStory.title}</i></h2>
                        <Container fluid>
                            <Row style={{paddingBottom: '2vw'}}>
                                {assocPhoto !== null && assocPhoto && (
                                    <Col>
                                        <img
                                            src={assocPhoto.accessUri}
                                            style={{maxWidth: '50vw'}}>
                                        </img>
                                        <p>{assocPhoto.caption} - <small className="text-muted">Uploaded on {new Date(assocPhoto.timestamp).toDateString()}</small></p>
                                    </Col>
                                )}
                                <Col>
                                    <h5 style={{paddingBottom: '2vw'}} className="text-muted">Created by {currentStory.author} on {new Date(currentStory.timestamp).toDateString()}</h5>
                                    {assocPhoto !== null && assocPhoto ? (
                                        <p style={{maxWidth: '50vw', textAlign: 'left', whiteSpace: 'pre-line', fontSize: 'large'}}>
                                            {currentStory.content}
                                        </p>
                                    ):
                                    (
                                        <p style={{paddingRight: '10vw', paddingLeft: '10vw', textAlign: 'left', whiteSpace: 'pre-line', fontSize: 'large'}}>
                                            {currentStory.content}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{paddingBottom: '2vw'}}>
                                <div id="tejas-create-form-attach-story-option" style={{fontFamily: "'Bellota', cursive"}}>
                                    <Button style={{margin: '1vw'}} variant="outline-secondary" onClick={() => navigate('/stories')}>Back to Stories</Button>
                                    <Button style={{margin: '1vw'}} variant="outline-info" onClick={() => navigate('/stories/create')}>Create your own story</Button>
                                </div>
                            </Row>
                        </Container>
                    </div>
                )
            )}
        </div>
    );
};

export default SingleStory;