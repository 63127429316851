import React from "react";

const About = (props) => {
    return (
        <div id="about" style={{
            fontFamily: "'EB Garamond'",
            width: "100vw",
            height: "100vh",
            maxWidth: "100%",
            overflowX: "hidden",
            backgroundImage: `url(${"/images/overlook.png"})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}>
            {/* <div id="precious-quote">
                <h2 style={{textAlign: "center"}}><i><b>"Time is precious,<br/>waste it wisely..."</b></i></h2>
            </div> */}
            <div id="about-summary" style={{
                width: "50vw",
                paddingTop: "10vh",
                paddingLeft: "10vh",
                textAlign: "left",
            }}>
                <p>
                Welcome to the digital memoriam of <b>Achintya Garikaparthi</b>, who passed away on July 27, 2018 in a tragic car accident.
                Achintya was a beloved son, brother, grandson, friend and colleague to many, and will be deeply missed by all who knew him.
                </p>
                <p> 
                He was brilliant and charismatic with a kind-hearted nature and profound sense of humor. Achintya was an avid fan of 
                soccer and a proud supporter of the New England Patriots. He had a passion for finance and business, which led him to study 
                at the esteemed Smith School of Business at Queen's University in Kingston, Canada.
                </p>
                <p>
                This website is a place for friends and family to share memories, photos, and condolences. It is our hope that this memorial
                will serve as a tribute to Achintya and help us all to remember the wonderful person he was.
                </p>
            </div>
        </div>
    );
};

export default About;