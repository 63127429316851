import { transformPictureToGridItem, transformStoryToStoryBoardItem } from './helpers.js';
import TejasClient from './tejasClient.js';

export const getPictures = async (ctxToken='', fetchSize=25) => {
    try{
        const response = await TejasClient.get('pictures/fetch', {
            params: {
                size: fetchSize
            },
            headers: {
                'x-tejas-ctxToken': ctxToken
            }
        });

        return {
            picList: transformPictureToGridItem(response.data.pictureList),
            ctxToken: response.data.nextPageToken
        };
    } catch(err){
        console.log(err);
        return undefined;
    }
};

export const uploadPhoto = async (imgFile, imgHeight, imgWidth, imgAuthor, imgCaption, associatedStoryId = undefined) => {
    try{
        let formData = new FormData();
        formData.append('uploaded_photo', imgFile);
        formData.append('pictureAuthor', imgAuthor);
        formData.append('pictureCaption', imgCaption);
        formData.append('imgHeight', imgHeight);
        formData.append('imgWidth', imgWidth);

        if (associatedStoryId){
            formData.append('associatedStoryId', associatedStoryId);
        }

        const response = await TejasClient.post('picture/upload', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });

        return response.status === 200 ? 200 : undefined;
    }
    catch(err){
        console.log(err);
        return undefined;
    }
};

export const getStories = async (ctxToken='', fetchSize=10) => {
    try{
        const response = await TejasClient.get('stories/fetch', {
            params: {
                size: fetchSize
            },
            headers: {
                'x-tejas-ctxToken': ctxToken
            }
        });

        return {
            storyList: transformStoryToStoryBoardItem(response.data.storyList),
            ctxToken: response.data.nextPageToken
        };
    } catch(err){
        console.log(err);
        return undefined;
    }
};

export const fetchSingleStory = async(storyIdToGet) => {
    try{
        const response = await TejasClient.get('story/get', {
            params: {
                storyId: storyIdToGet
            }
        });

        if (response.status === 200){
            return response.data;
        }

        throw new Error(`Photo with associated story ID ${storyIdToGet} not found`);
    }
    catch(err){
        console.log(err);
        return undefined;
    }
}

export const fetchPhotoByStoryId = async(storyIdToGet) => {
    try{
        const response = await TejasClient.get('picture/fetchByStory', {
            params: {
                associatedStoryId: storyIdToGet
            }
        });
        
        if (response.status === 200){
            return response.data;
        }

        throw new Error(`Photo with associated story ID ${storyIdToGet} not found`);
    }
    catch (err){
        console.log(err);
        return undefined;
    }
};

export const fetchSinglePhoto = async(photoIdToFetch) => {
    try{
        const response = await TejasClient.get('picture/get', {
            params: {
                photoId: photoIdToFetch
            }
        });

        if (response.status === 200){
            return response.data;
        }

        throw new Error(`Photo with ID ${photoIdToFetch} not found`);
    }
    catch(err){
        console.log(err);
        return undefined;
    }
};

export const createStory = async(author, title, content) => {
    try{
        const response = await TejasClient.post('story/create', {
            author: author,
            title: title,
            content: content
        });

        return response.data;
    }
    catch(err){
        console.log(err);
        return undefined;
    }
}

export const modifyStoryApprove = async(storyId, adminSecret, approvalValue) => {
    try{
        const response = await TejasClient.post('story/approve', {
            storyId: storyId,
            adminSecret: adminSecret,
            approvalValue: approvalValue
        });

        if (response.status === 200){
            return "OK!";
        }

        throw new Error(`Story approval for ID ${storyId} failed`);

    }
    catch(err){
        console.log(err);
        return undefined;
    }
}

export const modifyPictureApprove = async(pictureId, adminSecret, approvalValue) => {
    try{
        const response = await TejasClient.post('picture/approve', {
            pictureId: pictureId,
            adminSecret: adminSecret,
            approvalValue: approvalValue
        });

        if (response.status === 200){
            return "OK!";
        }

        throw new Error(`Story approval for ID ${pictureId} failed`);

    }
    catch(err){
        console.log(err);
        return undefined;
    }
}

export const sendNote = async(noteContent, noteAuthor, noteTitle) => {
    try{
        const response = await TejasClient.post('note/create', {
            noteContent: noteContent,
            noteAuthor: noteAuthor,
            noteTitle: noteTitle
        });

        if (response.status === 200){
            return response.data;
        }

        throw new Error(`Note creation by ${noteAuthor} failed with status ${response.status}`);
    }
    catch(err){
        console.log(err);
        return undefined;
    }
};