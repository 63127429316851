import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const StoryCard = ({ storyId, title, author, subtext, timestamp }) => {
    return (
        <Link to={`/stories/${storyId}`} style={{color: 'black', textDecoration: 'none'}}>
            <Card style={{flex: "1 1 30%", marginBottom: "50px"}}>
                <Card.Body>
                    <Card.Title>
                        {title.length < 25 ? title : title.substring(0, 24)+"..." }
                    </Card.Title>
                    <Card.Text>
                        <i>{subtext}</i>
                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">Created by {author} on {new Date(timestamp).toDateString()}</small>
                </Card.Footer>
            </Card>
        </Link>
    );
};

export default StoryCard;