import React, { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getStories } from "../../util/outbound";
import { Link } from "react-router-dom";
import Loader from "../shared/Loader";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StoryCard from "./StoryCard";

const StoryBoard = (props) => {
    const [initialLoad, setInitialLoad] = useState(true);
    const [currentCtxToken, setCurrentCtxToken] = useState('');
    const [currentStoryList, setcurrentStoryList] = useState([]);

    const fetchStoryForStoryBoard = async () => {
        const { storyList: stories, ctxToken: continuationToken } = await getStories(currentCtxToken);
        setcurrentStoryList(prevStories => [...prevStories, ...stories]);
        setCurrentCtxToken(continuationToken);
        if (initialLoad){
            setInitialLoad(false);
        }
    };

    useEffect(() => {
        const op = async () => {
            await fetchStoryForStoryBoard();
        }
        op();
    }, []);

    const renderStories = () => {
        return currentStoryList.map(function (aStory) {
            return <StoryCard 
                storyId={aStory.storyId}
                title={aStory.title}
                author={aStory.author}
                subtext={aStory.subtext}
                timestamp={aStory.timestamp}
            />
        });
    }

    const renderGrid = () => {
        const cards = renderStories();
        const rows = [...Array(Math.ceil(cards.length / 4))];
        const productRows = rows.map( (row, idx) => cards.slice(idx * 4, idx * 4 + 4));
        const content = productRows.map((row, idx) => (
            <Row>
                {row.map(product => <Col>{product}</Col> )}
            </Row>
        ));
        return (
            <Container>
                {content}
            </Container>
        );
    }

    return (
        <div id="tejas-story-board" style={{
            paddingTop: "5vw"
        }}>
            <div id="tejas-story-board-header" style={{fontFamily: "'Bellota', cursive", paddingBottom: '2vw'}}>
                <h2><i>Stories</i></h2>
                <div id="tejas-story-board-create-story-button" style={{ paddingTop: '2vw', float: 'center' }}>
                    <Link to={'/stories/create'} style={{color: 'black', textDecoration: 'none'}}>
                        <Button variant="outline-info">Create a Story</Button>
                    </Link>
                </div>
            </div>
            <div id="tejas-story-board-content" style={{fontFamily: "'EB Garamond'", paddingTop: '4vw'}}>
                {initialLoad ? (
                    <Loader style={{paddingTop: '10vw'}}/>
                )
                : (
                    <InfiniteScroll
                        dataLength={currentStoryList.length}
                        hasMore={currentCtxToken && currentCtxToken !== ''}
                        next={fetchStoryForStoryBoard}
                        loader={<Loader/>}
                        endMessage={
                            <div id="tejas-stories-top-button" style={{fontFamily: "'Bellota', cursive", paddingBottom: '2vw'}}>
                                <Button variant="outline-info" onClick={() => window.scrollTo(0, 0)}>Back To Top</Button>
                            </div>
                        }
                    >
                        {renderGrid()}
                    </InfiniteScroll>
                )}
            </div>
        </div>
    );

};

export default StoryBoard;