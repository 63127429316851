import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../shared/Loader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { sendNote } from "../../util/outbound";

const Contact = (props) => {
    const [noteSent, setNoteSent] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [contactFormValidated, setContactFormValidated] = useState(false);

    const [contactNoteName, setContactNoteName] = useState(undefined);
    const [contactNoteTitle, setContactNoteTitle] = useState(undefined);
    const [contactNoteContent, setContactNoteContent] = useState(undefined);

    const [errorEncountered, setErrorEncountered] = useState(false);
    const [isDirty, setDirty] = useState(false);
    const navigate = useNavigate();

    const handleContactTitleChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setContactNoteTitle(e.target.value);
    }

    const handleContactAuthorChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setContactNoteName(e.target.value);
    }

    const handleContactContentChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setContactNoteContent(e.target.value);
    }

    const handleContactFormSubmitted = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setErrorEncountered(false);
        if (form.checkValidity() === false) {
            setContactFormValidated(true);
        }
        else{
            setLoading(true);
            const createdNote = await sendNote(contactNoteContent, contactNoteName, contactNoteTitle);
            if (createdNote){
                setNoteSent(true);
            }
            else{
                setErrorEncountered(true);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleDirtyClose = event => {
            event.preventDefault();
            return (event.returnValue = 'Are you sure you want to exit?');
        };
        
        if (isDirty){
            window.addEventListener('beforeunload', handleDirtyClose);
        }

        if (noteSent){
            window.removeEventListener('beforeunload', handleDirtyClose);
        }
    
        return () => {
            window.removeEventListener('beforeunload', handleDirtyClose);
        };
    }, [isDirty, noteSent]);

    return (
        <div id="tejas-create-note" style={{
            paddingTop: "5vw"
        }}>
            <div id="tejas-create-note-header" style={{fontFamily: "'Bellota', cursive", paddingBottom: '5vw'}}>
                <h2><i>Write a personal note to the family</i></h2>
            </div>
            <div id="tejas-create-note-content" style={{paddingLeft: '15vw', paddingRight: '15vw'}}>
                {isLoading && <Loader />}
                {!isLoading && !noteSent && (
                    <Form noValidate validated={contactFormValidated} onSubmit={handleContactFormSubmitted}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter your note's title" minLength={1} maxLength={64} onChange={handleContactTitleChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a title.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter your name" minLength={1} maxLength={32} onChange={handleContactAuthorChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group style={{paddingTop: '2vw'}}>
                                    <Form.Label>Note</Form.Label>
                                    <Form.Control as="textarea" rows={5} required placeholder="Write your note: 50 - 1000 characters" minLength={50} maxLength={1000} onChange={handleContactContentChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please write your note between 50 and 1000 characters.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center" style={{paddingTop: '2vw'}}>
                            <Col>
                                <Button variant="outline-primary" type="submit">Submit</Button>
                                {errorEncountered && <p style={{color: 'red'}}>Something went wrong. Please try again.</p>}
                            </Col>
                        </Row>
                    </Form>
                )}
                {!isLoading && noteSent && (
                    <div id="tejas-create-note-success" style={{fontFamily: "'Bellota', cursive"}}>
                        <h5 style={{color: 'green'}}>Your note was sent successfully!</h5>
                        <Button style={{margin: '1vw'}} variant="outline-secondary" onClick={() => navigate('/')}>Back to Home</Button>
                        <Button style={{margin: '1vw'}} variant="outline-info" onClick={() => navigate('/stories/create')}>Create your own story</Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Contact;