import React, { useEffect, useState } from "react";
import SingleStory from "./SingleStory";
import { useParams } from "react-router-dom";
import { fetchSingleStory, fetchPhotoByStoryId, fetchSinglePhoto, modifyPictureApprove, modifyStoryApprove } from "../../util/outbound";
import Loader from "../shared/Loader";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const ApproveResource = (props) => {
    const params = useParams();
    const resourceId = params.id;
    const [isLoading, setLoading] = useState(false);

    const [storyFound, setStoryFound] = useState(false);
    const [picFound, setPicFound] = useState(false);
    const [nothingFound, setNothingFound] = useState(false);

    const [pictureObject, setPictureObject] = useState(undefined);
    const [currentStoryApprovedValue, setCurrentStoryApprovedValue] = useState(false);
    const [currentPhotoApprovedValue, setCurrentPhotoApprovedValue] = useState(false);

    const [adminSecret, setAdminSecret] = useState(undefined);

    const [picApprovalFailed, setPicApprovalFailed] = useState(false);
    const [picApprovalLoading, setPicApprovalLoading] = useState(false);
    const [storyApprovalFailed, setStoryApprovalFailed] = useState(false);
    const [storyApprovalLoading, setStoryApprovalLoading] = useState(false);

    useEffect(() => {
        const performAsyncOp = async () => {
            setLoading(true);
            const storyResource = await fetchSingleStory(resourceId);
            if (storyResource){
                setStoryFound(true);
                setCurrentStoryApprovedValue(storyResource.approved);
                // fetch pic by associated story ID
                const associatedPhotoResource = await fetchPhotoByStoryId(resourceId);
                if (associatedPhotoResource){
                    setPicFound(true);
                    setPictureObject(associatedPhotoResource);
                    setCurrentPhotoApprovedValue(associatedPhotoResource.approved);
                }
            }
            else{
                // try to fetch pic alone
                const photoResource = await fetchSinglePhoto(resourceId);
                if (photoResource){
                    setPicFound(true);
                    setPictureObject(photoResource);
                    setCurrentPhotoApprovedValue(photoResource.approved);
                }
                else{
                    // nothing found
                    setNothingFound(true);
                }
            }
            setLoading(false);
        };

        performAsyncOp();
    }, []);

    const handleAdminSecretChange = (e) => {
        if (e.target.value !== undefined && e.target.value !== ''){
            setAdminSecret(e.target.value);
        }
        else{
            setAdminSecret(undefined);
        }
    };

    const handlePicApprovalChange = async (e) => {
        setPicApprovalLoading(true);
        if (picApprovalFailed){
            setPicApprovalFailed(false);
        }

        const res = await modifyPictureApprove(pictureObject.id, adminSecret, e.target.checked);
        if (res){
            setCurrentPhotoApprovedValue(e.target.checked);
        }
        else{
            setPicApprovalFailed(true);
        }
        setPicApprovalLoading(false);

        if (e.target.checked && !currentStoryApprovedValue){
            // picture cannot be approved with the story being approved too
            await handleStoryApprovalChange(e);
        }
    }

    const handleStoryApprovalChange = async (e) => {
        setStoryApprovalLoading(true);
        if (storyApprovalFailed){
            setStoryApprovalFailed(false);
        }
        
        const res = await modifyStoryApprove(resourceId, adminSecret, e.target.checked);
        if (res){
            setCurrentStoryApprovedValue(e.target.checked);
        }
        else{
            setStoryApprovalFailed(true);
        }
        setStoryApprovalLoading(false);
        // picture will always be disapproved if associated story is disapproved
        if (picFound && currentPhotoApprovedValue && !e.target.checked){
            await handlePicApprovalChange(e);
        }
    }

    return (
        <div id="tejas-approve-form" style={{
            paddingTop: '5vw',
            fontFamily: "'EB Garamond'"
        }}>
            <div id="tejas-approve-form-header">
                <h2 style={{paddingBottom: '2vw'}}>Edit Approval Form</h2>
                <h5>Preview the resource and use the form below to edit the approval status.</h5>
            </div>
            {isLoading ? (
                <Loader style={{paddingTop: '5vw'}} />
            ) : (
                !nothingFound ? (
                    <div id="tejas-approve-form-preview">
                        <div id="tejas-approve-form-component" style={{paddingLeft: '15vw', paddingRight: '15vw', paddingTop: '4vw'}}>
                            <Container fluid>
                                <Row>
                                    <Col xs={7}>
                                        <Form.Group>
                                            <Form.Label>Administrative Secret</Form.Label>
                                            <Form.Control required type="password" placeholder="Enter your admin password" onChange={handleAdminSecretChange}/>
                                        </Form.Group>
                                    </Col>
                                    {storyFound && (
                                        <Col>
                                            {storyApprovalLoading ? (
                                                <Loader />
                                            ) : (
                                                <Form>
                                                    <Form.Label>Approve Story</Form.Label>
                                                    <Form.Check
                                                        disabled={adminSecret === undefined}
                                                        checked={currentStoryApprovedValue} 
                                                        type="switch"
                                                        id="story-approve-switch"
                                                        onChange={handleStoryApprovalChange}
                                                    />
                                                </Form>
                                            )}
                                            {storyApprovalFailed && (
                                                <p style={{color: 'red'}}>Something went wrong. Please try again.</p>
                                            )}
                                        </Col>
                                    )}
                                    {picFound && (
                                        <Col>
                                            {picApprovalLoading ? (
                                                <Loader />
                                            ) : (
                                                <Form>
                                                    <Form.Label>Approve Picture</Form.Label>
                                                    <Form.Check
                                                        disabled={adminSecret === undefined}
                                                        checked={currentPhotoApprovedValue} 
                                                        type="switch"
                                                        id="picture-approve-switch"
                                                        onChange={handlePicApprovalChange}
                                                    />
                                                </Form>
                                            )}
                                            {picApprovalFailed && (
                                                <p style={{color: 'red'}}>Something went wrong. Please try again.</p>
                                            )}
                                        </Col>
                                    )}
                                </Row>
                            </Container>
                        </div>
                        <div id="tejas-approve-form-preview-found" style={{paddingTop: '4vw'}}>
                            {storyFound && (
                                <SingleStory respectDenial={false} />
                            )}
                            {!storyFound && picFound && (
                                // add a pic only component here
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <img
                                                src={pictureObject.accessUri}
                                                style={{maxWidth: '50vw'}}>
                                            </img>
                                            <p>{pictureObject.caption} - <small className="text-muted">Uploaded on {new Date(pictureObject.timestamp).toDateString()}</small></p>
                                        </Col>
                                    </Row>
                                </Container>
                            )}
                        </div>
                    </div>
                ) : (
                    <div id="tejas-approve-form-no-preview" style={{color: 'red', fontSize: 'large', paddingTop: '4vw'}}>
                        <p>No resources were found to edit approvals for.<br/>If you believe this is an error, please try again by refreshing the page.</p>
                    </div> 
                )
            )}
        </div>
    );
}

export default ApproveResource;