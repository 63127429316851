export const transformPictureToGridItem = (picList) => {
    const res = []

    if (picList.length !== undefined && picList.length > 0) {
        picList.forEach(element => {
            res.push({
                src: element.accessUri,
                original: element.accessUri,
                width: element.imageWidth,
                height: element.imageHeight,
                caption: `${element.caption} - Uploaded by ${element.author}`
            });
        });
    }
    
    return res;
}

export const transformStoryToStoryBoardItem = (storyList) => {
    const res = [];

    if (storyList.length !== undefined && storyList.length > 0){
        storyList.forEach(element => {
            res.push({
                storyId: element.id,
                author: element.author,
                title: element.title,
                timestamp: element.timestamp,
                subtext: element.subtext
            });
        });
    }

    return res;
}