import React from 'react';

const Landing = (props) => {
    return (
        <div id="main-landing-screen">
            <div id="welcome-panel" style={{
                backgroundImage: `url(${"/images/action-quidditch.jpg"})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: "0.5",
                width: "100vw",
                height: "100vh",
                maxWidth: "100%",
                overflowX: "hidden",
                }}>
            </div>
            <div id="welcome-panel-message" style={{
                backgroundColor: "transparent",
                // fontFamily: "'Roboto Condensed', sans-serif",
                fontFamily: "'Bellota', cursive",
                position: "absolute",
                opacity: "1",
                top: "40%",
                left: "15%",
                right: "15%",
                color: "black",
                textAlign: "center",
                justifyContent: "center"
                }}>
                <h1>Welcome to <b>Tejas</b></h1>
                <br/>
                <h3>A digital memoriam for <b>Achintya T. Garikaparthi</b><br/>October 13, 1999 - July 27, 2018</h3>
            </div>
        </div>
    );
};

export default Landing;