import React, { useEffect, useState } from "react";

import {
    Navbar as ReactNavBar,
    Nav
} from 'react-bootstrap';

const TejasNavbar = (props) => {
    return (
        <div className="mainNavbar">
            <ReactNavBar expand="lg" variant="light" style={{backgroundColor: "azure"}}>
                <ReactNavBar.Brand href="/" style={{fontFamily: "'Bellota', cursive"}}>
                    <img
                    src="/logo-scaled.png"
                    width="60"
                    height="30"
                    className="d-inline-block align-top"
                    alt="ATG alt logo"
                    />
                    <b>Tejas</b>
                </ReactNavBar.Brand>
                <ReactNavBar.Toggle aria-controls="basic-navbar-nav" />
                <ReactNavBar.Collapse>
                    <Nav className="ml-auto" style={{fontFamily: "'Bellota', cursive", fontWeight: "bolder"}}>
                        <Nav.Link href="/#about">About</Nav.Link>
                        <Nav.Link href="/gallery">Gallery</Nav.Link>
                        <Nav.Link href="/stories">Stories</Nav.Link>
                        <Nav.Link href="/contact">Contact Us</Nav.Link>
                    </Nav>
                </ReactNavBar.Collapse>
            </ReactNavBar>
        </div>
    );
};

export default TejasNavbar;