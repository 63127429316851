import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { uploadPhoto } from "../../util/outbound";
import Loader from "../shared/Loader";

const AddPictureForm = (props) => {

    const [validated, setValidated] = useState(false);
    const [uploadedPic, setUploadedPic] = useState(null);
    const [imgWidth, setImgWidth] = useState(0);
    const [imgHeight, setImgHeight] = useState(0);
    const [imgAuthor, setImgAuthor] = useState(null);
    const [imgCaption, setImgCaption] = useState(null);
    const [errorEncountered, setErrorEncountered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        setErrorEncountered(false);
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
        }
        else{
            // make api call using states
            setIsLoading(true);
            const isUploaded = await uploadPhoto(uploadedPic, imgHeight, imgWidth, imgAuthor, imgCaption, props.associatedStoryId);
            setIsLoading(false);
            if (isUploaded === undefined) {
                setErrorEncountered(true);
            }
            else{
                props.onUploadCompleted();
                window.location.reload();
            }
        }
    };

    const onChangePicture = (e) => {
        setUploadedPic(e.target.files[0]);
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function (ev) {
            var img = new Image();
            img.src = ev.target.result;
            img.onload = function () {
                setImgWidth(img.naturalWidth);
                setImgHeight(img.naturalHeight);
                return true;
            }
        };
    };

    const onChangeAuthor = (e) => {
        setImgAuthor(e.target.value);
    };

    const onChangeCaption = (e) => {
        setImgCaption(e.target.value);
    };

    return (
        <>
            {isLoading ?
                (
                    <Loader style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}/>
                )
                :
                (
                    <Form encType="multipart/form-data" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="picFormFile" className="mb-3">
                            <Form.Label>Select Photo - Maximum 6 MB</Form.Label>
                            <Form.Control type="file" name="uploaded_photo" accept=".png,.jpg,.jpeg" required onChange={onChangePicture} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="picFormCaption">
                            <Form.Label>Photo Caption</Form.Label>
                            <Form.Control placeholder="Input caption here" maxLength={64} required onChange={onChangeCaption}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a caption no longer than 64 characters.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="picFormAuthor">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control maxLength={32} placeholder="Your Name" required onChange={onChangeAuthor}/>
                            <Form.Control.Feedback type="invalid">
                                Please provide a name no longer than 32 characters.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <p style={{color: 'coral', fontFamily: "'EB Garamond'"}}><b>Note:</b> Your story will be sent for approval before being shown on the site.</p>
                        {errorEncountered && <p style={{color: 'red'}}>Something went wrong. Please try again.</p>}
                        <Button type="submit">Submit</Button>
                    </Form>
            )}
        </>
    );
};

export default AddPictureForm;