import React from "react";
import GridLoader from 'react-spinners/GridLoader';

const Loader = (props) => {
    return (
        <div style={props.style}>
            <GridLoader
                color="rgb(54, 215, 183)"
                loading={true}
                aria-label="Loading Grid"
                data-testid="loader"
                size={25}
            />
        </div>
        
    );
};

export default Loader;