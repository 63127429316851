import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Loader from "../shared/Loader";
import Button from 'react-bootstrap/Button';
import AddPictureForm from "./AddPictureForm";
import { getPictures } from "../../util/outbound";
import { Gallery as GridGallery } from "react-grid-gallery";
import Lightbox from "react-18-image-lightbox";
import 'react-18-image-lightbox/style.css';

const Gallery = (props) => {

    const [initialLoad, setInitialLoad] = useState(true);
    const [nextLoading, setNextLoading] = useState(false);
    const [showPictureModal, setShowPictureModal] = useState(false);
    const [currentCtxToken, setCurrentCtxToken] = useState('');
    const [currentImageList, setCurrentImageList] = useState([]);

    const hideModal = () => {
        setShowPictureModal(false);
    };

    const showModal = () => {
        setShowPictureModal(true);
    }

    useEffect(() => {
        const op = async() => {
            const { picList: pictures, ctxToken: continuationToken } = await getPictures(currentCtxToken);
            setCurrentImageList(prevImgs => [...prevImgs, ...pictures]);
            setCurrentCtxToken(continuationToken);
            if (initialLoad){
                setInitialLoad(false);
            }
            if (nextLoading){
                setNextLoading(false);
            }
        }
        if (initialLoad || (currentCtxToken && currentCtxToken !== '' && nextLoading)){
            op();
        }
    }, [nextLoading]);

    const [index, setIndex] = useState(-1);
    const currentImage = currentImageList[index];
    const nextIndex = (index + 1) % currentImageList.length;
    const nextImage = currentImageList[nextIndex] || currentImage;
    const prevIndex = (index + currentImageList.length - 1) % currentImageList.length;
    const prevImage = currentImageList[prevIndex] || currentImage;

    const handleClick = (index) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    useEffect(() => {
        const onScroll = () => {
            // To-Do -- add logic to check if body height is less than window height and if ctx token is present, then issue query
            if (((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight)){
                setNextLoading(true);
            }
        }
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    return (
        <div id="tejas-gallery" style={{
            paddingTop: '5vw',
            paddingBottom: '4vw'
        }}>
            <div id="tejas-gallery-header" style={{
                fontFamily: "'Bellota', cursive"
            }}>
                <h2><i>Gallery</i></h2>
        
                <div id="tejas-gallery-upload-pic-button" style={{ paddingRight: '4vw', float: 'right' }}>
                    <Button variant="outline-info" onClick={showModal}>Upload a Picture</Button>
                </div>

                {showPictureModal && (
                    <div id="tejas-gallery-add-picture">
                        <Modal size="lg" show={showPictureModal} onHide={hideModal} backdrop="static" centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Upload a Picture</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <AddPictureForm onUploadCompleted={hideModal} />
                            </Modal.Body>
                        </Modal>
                    </div>
                )}

                {initialLoad ? (
                    <Loader style={{paddingTop: '10vw'}}/>
                )
                :
                (
                    <div id="tejas-gallery-content" style={{
                        paddingTop: '5vw',
                        paddingLeft: '2vw',
                        paddingRight: '2vw'
                    }}>
                        <GridGallery 
                            images={currentImageList}
                            onClick={handleClick}
                            enableImageSelection={false}
                            rowHeight={360}
                        />
                        {!!currentImage && (
                            <Lightbox
                                mainSrc={currentImage.original}
                                imageCaption={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.original}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.original}
                                prevSrcThumbnail={prevImage.src}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Gallery;