import React, { useState, useEffect } from "react";
import Loader from "../shared/Loader";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AddPictureForm from "../gallery/AddPictureForm";
import { createStory } from "../../util/outbound";
import { useNavigate } from "react-router-dom";

const CreateStory = (props) => {
    const [storyCreated, setStoryCreated ] = useState(false);
    const [createdStoryId, setCreatedStoryId] = useState(undefined);
    const [storyFormValidated, setStoryFormValidated] = useState(false);
    const [errorEncountered, setErrorEncountered] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [attachStory, setAttachStory] = useState(false);
    const [isDirty, setDirty] = useState(false);

    const navigate = useNavigate();

    // story states
    const [storyTitle, setStoryTitle] = useState(undefined);
    const [storyContent, setStoryContent] = useState(undefined);
    const [storyAuthor, setStoryAuthor] = useState(undefined);

    const handleStorySubmit = async(event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setErrorEncountered(false);
        if (form.checkValidity() === false) {
            setStoryFormValidated(true);
        }
        else{
            setLoading(true);
            const createdStory = await createStory(storyAuthor, storyTitle, storyContent);
            if (createdStory){
                setStoryCreated(true);
                setCreatedStoryId(createdStory.id);
            }
            else{
                setErrorEncountered(true);
            }
            setLoading(false);
        }
    };

    const handleStoryTitleChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setStoryTitle(e.target.value);
    };

    const handleStoryContentChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setStoryContent(e.target.value);
    };

    const handleStoryAuthorChange = (e) => {
        if (!isDirty){
            setDirty(true);
        }
        setStoryAuthor(e.target.value);
    };

    const redirectToStoriesAfterPicUpload = () => {
        navigate('/stories');
    };

    const showPicAttachForm = () => {
        setAttachStory(true)
    };

    useEffect(() => {
        const handleDirtyClose = event => {
            event.preventDefault();
            return (event.returnValue = 'Are you sure you want to exit?');
        };
        
        if (isDirty){
            window.addEventListener('beforeunload', handleDirtyClose);
        }

        if (storyCreated){
            window.removeEventListener('beforeunload', handleDirtyClose);
        }
    
        return () => {
            window.removeEventListener('beforeunload', handleDirtyClose);
        };
    }, [isDirty, storyCreated]);

    return (
        <div id="tejas-create-story" style={{
            paddingTop: "5vw"
        }}>
            <div id="tejas-create-story-header" style={{fontFamily: "'Bellota', cursive", paddingBottom: '2vw'}}>
                <h2><i>Write your heart out...</i></h2>
            </div>
            <div id="tejas-create-forms" style={{paddingLeft: '15vw', paddingRight: '15vw'}}>
                {isLoading && <Loader />}
                {!storyCreated && !isLoading && (
                    <Form noValidate validated={storyFormValidated} onSubmit={handleStorySubmit}>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter your story's title" maxLength={32} onChange={handleStoryTitleChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a title.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Your Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter your name" maxLength={32} onChange={handleStoryAuthorChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group style={{paddingTop: '2vw'}}>
                                    <Form.Label>Story</Form.Label>
                                    <Form.Control as="textarea" rows={10} required placeholder="Write your story: 100 - 3000 characters" minLength={100} maxLength={3000} onChange={handleStoryContentChange}/>
                                    <Form.Control.Feedback type="invalid">
                                        Please write your story between 100 and 3000 characters.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="align-items-center" style={{paddingTop: '2vw'}}>
                            <Col>
                                <p style={{color: 'coral', fontFamily: "'EB Garamond'"}}><b>Note:</b> Your story will be sent for approval before being shown on the site.</p>
                                <Button variant="outline-primary" type="submit">Submit</Button>
                                {errorEncountered && <p style={{color: 'red'}}>Something went wrong. Please try again.</p>}
                            </Col>
                        </Row>
                    </Form>
                )}
                {storyCreated && !isLoading && (
                    <Modal size="lg" show={true} onHide={redirectToStoriesAfterPicUpload} backdrop="static" centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Attach a picture?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {attachStory ? (
                                <AddPictureForm associatedStoryId={createdStoryId} onUploadCompleted={redirectToStoriesAfterPicUpload} />
                            ) : (
                                <div id="tejas-create-form-attach-story-option">
                                    <p style={{textAlign: 'center'}}>If you'd like, you can choose to attach a picture to your story <b>at this time only.</b></p>
                                    <Button variant="outline-info" onClick={showPicAttachForm} style={{float: 'right'}}>Continue</Button>
                                    <Button variant="outline-secondary" onClick={redirectToStoriesAfterPicUpload} style={{float: 'right', marginRight: '1vw'}}>No, thanks</Button>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default CreateStory;