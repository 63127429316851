import React from "react";

const NotFound = () => {
    return (
        <div id="tejas-not-found" style={{
            paddingTop: "10vw",
            fontFamily: "'EB Garamond'"
        }}>
           <h2 style={{paddingBottom: '2vw'}}>Page not Found</h2>
           <h5>The page/resource you were looking for was either not found or has been deleted.<br/>Please use the above links to navigate the site.</h5>
        </div>
    );
};

export default NotFound;