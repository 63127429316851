import './App.css';

import { Route, Routes, Navigate } from 'react-router-dom';

import Landing from './components/landing/Landing';
import TejasNavbar from './components/layout/Navbar';
import About from './components/landing/About';
import Gallery from './components/gallery/Gallery';
import StoryBoard from './components/stories/StoryBoard';
import SingleStory from './components/stories/SingleStory';
import CreateStory from './components/stories/CreateStory';
import NotFound from './components/landing/NotFound';
import ApproveResource from './components/stories/ApproveResource';
import Contact from './components/contact/Contact';

function App() {
  return (
      <div className="App">
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
          integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
          crossOrigin='anonymous'
        />
        <TejasNavbar />
        <Routes>
          <Route path="/" element={<><Landing/><About/></>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/stories" element={<StoryBoard/>} />
          <Route path="/stories/:id" element={<SingleStory/>} />
          <Route path="/resource/:id/approve" element={<ApproveResource/>} />
          <Route path="/stories/create" element={<CreateStory />} />
          <Route path="/contact" element={<Contact />} />
          {/* 404 Not Found */}
          <Route path="/404" element={<NotFound/>}></Route>
          <Route path="*" element={ <Navigate to="/404" replace />}></Route>
        </Routes>
      </div>
  );
}

export default App;
